@import url(https://fonts.bunny.net/css?family=arsenal:400,700,400i,700i);


* {
  /* outline: lime 1px solid; */
  font-family: "arsenal";
  font-size: 24px;
  --t-yellow: #f6d17a;
  --t-red: #97463f;
  --t-blue: 41, 99, 165;
  --t-black: #3f3d4a;
  --t-grey: rgb(191, 191, 191);
  --t-green: #8EC469;
  --t-border-green: 142, 196, 105;
  --t-cheerio-red: 187, 28, 83;
  --t-cheerio-orange: 221, 119, 52;
  --t-cheerio-purple: 162, 88, 142;
  --t-cheerio-github: 3, 119, 237;
  --t-cheerio-lemon: 218, 237, 34;
  
}

#root {
  background-color: rgba(var(--t-border-green), .05);
}

.first-tab {
  margin: 1vh;  
  background-color: var(--t-yellow);
}

.second-tab {
  margin: 1vh;  
  background-color: var(--t-blue);
}

.third-tab {
  margin: 1vh;  
  background-color: var(--t-black);
}

.landing {
  background-color: blue;
}

.logo {
  position: relative;
  /* left: min(2vh, 1vh); */
  height: 75px;
}

.hire_me {
  position: relative;
  /* left: 50%; */
  top: 10%;
  height: 100px;
}

.header {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  /* flex-basis: auto; */
  /* justify-content: auto; */
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  height: 120px;
  border-bottom: .3vh solid var(--t-black);
  background-color: rgba(var(--t-border-green),1);
  white-space: nowrap;
  /* z-index: -2; */
  overflow: hidden;
  text-decoration: none;
}

.header-left{
  display: flex;
  font-size: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.header-left > * {
  /* Spacing of things in the left part of the header */
  padding: .5vh;
}

.header-right {
  position: relative;
  display: flex;
  margin-left: auto;
  gap: .75vh;
  justify-content: flex-start;  
}

.hamburger {
  height: 45px;
}

.hamburger:hover {
  cursor: pointer;
}

.header-button {
  display: flex;
  margin-left: auto;
}

.links {
  position: relative;
  display: flex;
  align-items: right;
  justify-content: flex;
  gap: .5vh;
}


/* 
Most of the below code was for the old styling of the site.
.links > a {
  position: relative;
  padding: .75vh;
  border-radius: 1vh;
  border: .25vh solid var(--t-black);
} */
/* 
a[class*="header-"]::before {
  content: "";
  border-radius: 1vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 100%;
  background-color: var(--t-green);
  transition: transform .5s ease;
  z-index: -1;
  transform: translateX(-100%);
}
 */
/* 
div.links > a:hover::before {
  transform: translateX(0);
}

[class|=header]{
  overflow: hidden;
} */

.landing-page {
  position: relative;
  display: grid;
  grid-template-columns: calc(1*100vw/3) 0px calc(2*100vw/3);
  height: 100rvh;
}

.leftSide {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  top: 2em;
  /* height: 100vh; */
  /* gap: 2em; */
  /* left: max(1vw, 7em); */
  /* width: 0em; */
}

.resume-heading {
  top: 1em;
  text-indent: 5em;
}

[class^="item"] {
  position: relative;
  display: inline-block;
  /* flex-direction: column; */
  border: #3f3d4a solid 2px;
  border-radius: 1em;
  width: calc(0.9 * 100vw/3);
  /* height: 5em; */
  margin-top: .5em;
  margin-left: .5em;
  left: min(calc(0.4 * 100vw/3), .5em);
}

[class^="item"] > p {
  text-indent: 1em;
  font-size: medium;
  margin: .5em;
  color: black;
  word-wrap: none;
}

.vl {
  position: relative;
  /* left: max(17.2em); */
  top: 10%;
  width: 0px;
  height: 80%;
  border: var(--t-grey) solid 1px;
}

.rightSide {
  position: relative;
  /* left: max(calc(1*100vw/3), 15em); */
  /* width: calc((196.5*100vw/300)); */
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.outer-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 25%;
  border-radius: 50%;
  /* --big-green-cheerio-diameter: 35vh; */
  --big-green-cheerio-diameter: 15em;
  height: var(--big-green-cheerio-diameter);
  width: var(--big-green-cheerio-diameter);
  background-color: #8EC469;
}

.inner-circle {
  position: relative;
  border-radius: 50%;
  background-color: #fff;
  height: 60%;
  width: 60%;
}

.bubbles {
  /* In order for inner-circle and bubbles to be on the same center, bubbles must be position: absolute */
  position: absolute;
  --diameter-bubbles: calc(var(--big-green-cheerio-diameter));
  border-radius: 100%;
  --translation: calc(var(--big-green-cheerio-diameter) * 110 / 100);
  height: var(--diameter-bubbles);
  width: var(--diameter-bubbles);
}

@keyframes myOrbit1 {
  from {
    transform: rotate(45deg) translateX(var(--translation)) rotate(-45deg);
  }

  to {
    transform: rotate(405deg) translateX(var(--translation)) rotate(-405deg);
  }
}

@keyframes myOrbit2 {
  from {
    transform: rotate(135deg) translateX(var(--translation)) rotate(-135deg);
  }

  to {
    transform: rotate(495deg) translateX(var(--translation)) rotate(-495deg);
  }
}

@keyframes myOrbit3 {
  0% {
    transform: rotate(225deg) translateX(var(--translation)) rotate(-225deg);
  }

  100% {
    transform: rotate(585deg) translateX(var(--translation)) rotate(-585deg);
  }
}

@keyframes myOrbit4 {
  from {
    transform: rotate(315deg) translateX(var(--translation)) rotate(-315deg);
  }

  to {
    transform: rotate(675deg) translateX(var(--translation)) rotate(-675deg);
  }
}

.bubble-outer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


[class$="-bubble"] {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-size: small;
  text-align: center;
  justify-content: center;
  align-items: center;
  --link-cheerio-outer-radius: calc(6/7*(var(--big-green-cheerio-diameter)));
  border-radius: 100%;
  width: var(--link-cheerio-outer-radius);
  height: var(--link-cheerio-outer-radius);
}

[class$="-bubble"] > a {
  position: relative;
  display: flex;
  border-radius: 100%;
  color: var(--t-black);
  text-decoration: none;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  font-size: medium;
  align-items: center;
}

.bubble-inner {
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: calc(0.41 * 0.3 * var(--link-cheerio-outer-radius)) solid salmon; */
  width: calc(0.3 * var(--link-cheerio-outer-radius));
  height: calc(0.3 * var(--link-cheerio-outer-radius));
}

[class$="-bubble"]:nth-child(even) > a {
  border: calc(0.22 * var(--link-cheerio-outer-radius)) solid;
  border-color: rgba(var(--t-blue), 1.0);
}

[class$="-bubble"]:nth-child(odd) > a {
  border: calc(0.22 * var(--link-cheerio-outer-radius)) solid;
  border-color: rgba(var(--t-blue), 0.8);
}

.project1-bubble {
  animation: myOrbit1 20s linear infinite;
}

.project2-bubble {
  animation: myOrbit3 20s linear infinite;
}

.linkedin-bubble {
  animation: myOrbit2 20s linear infinite;
}

.github-bubble {
  animation: myOrbit4 20s linear infinite;
}

.bubbles:hover * {
  animation-play-state: paused;
  animation-fill-mode: forwards;
}

.github-bubble:hover > a {
  border-color: rgba(var(--t-cheerio-red), 1.0);
  cursor: pointer;
}

.linkedin-bubble:hover > a {
  border-color: rgba(var(--t-cheerio-lemon), 1.0);
  cursor: pointer;
}

.project1-bubble:hover > a{
  border-color: rgba(var(--t-cheerio-orange), 1.0);
  cursor: pointer;
}

.project2-bubble:hover > a{
  border-color: rgba(var(--t-cheerio-purple), 1.0);
  cursor: pointer;
}

.footer {
  position: relative;
  display: flex;
  background-color: var(--t-grey);
  bottom: 0;
}
/* 
@media screen and (max-width: 800px) {
  
} */